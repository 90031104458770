body {
  margin: 0;
  padding: 0;
  background-size: 100%;
  background: url("./assets/images/bg.gif");
  background-repeat: repeat;
  background-attachment: fixed;
  height: 100%;
}

div#appContainer {
  width: 100%; 
  height: 100%; 
  position: fixed;
}

body {
  font-family: 'Roboto' !important;
  font-size: 13px !important;
}
