.spinner {
  top:50%;
  position:absolute;
  z-index:999;
}

.spinOverlay {
 width:100%;
 height:100%;
 background-color:hsla(180,0%,42%,.4);
 position:fixed;
 z-index:9999999;
 top:0;
 left:0
}

.sk-circle {
  margin:20px auto;
  width:40px;
  height:40px;
  position:relative
}

.sk-circle .sk-child {
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0
}

.sk-circle .sk-child:before {
  content:"";
  display:block;
  margin:0 auto;
  width:10%;
  height:30%;
  background-color:#274f66;
  border-radius:100%;
  animation:sk-circleBounceDelay 1.2s infinite ease-in-out both
}

.sk-circle .sk-circle2 {
  transform:rotate(30deg)
}

.sk-circle .sk-circle3 {
  transform:rotate(60deg)
}

.sk-circle .sk-circle4 {
  transform:rotate(90deg)
}

.sk-circle .sk-circle5 {
  transform:rotate(120deg)
}

.sk-circle .sk-circle6 {
  transform:rotate(150deg)
}

.sk-circle .sk-circle7 {
  transform:rotate(180deg)
}

.sk-circle .sk-circle8 {
  transform:rotate(210deg)
}

.sk-circle .sk-circle9 {
  transform:rotate(240deg)
}

.sk-circle .sk-circle10 {
  transform:rotate(270deg)
}

.sk-circle .sk-circle11 {
  transform:rotate(300deg)
}

.sk-circle .sk-circle12 {
  transform:rotate(330deg)
}

.sk-circle .sk-circle2:before {
  animation-delay:-1.1s
}

.sk-circle .sk-circle3:before {
  animation-delay:-1s
}

.sk-circle .sk-circle4:before {
  animation-delay:-.9s
}

.sk-circle .sk-circle5:before {
  animation-delay:-.8s
}

.sk-circle .sk-circle6:before {
  animation-delay:-.7s
}

.sk-circle .sk-circle7:before {
  animation-delay:-.6s
}
.sk-circle .sk-circle8:before {
  animation-delay:-.5s
}

.sk-circle .sk-circle9:before {
  animation-delay:-.4s
}

.sk-circle .sk-circle10:before {
  animation-delay:-.3s
}

.sk-circle .sk-circle11:before {
  animation-delay:-.2s
}

.sk-circle .sk-circle12:before {
  animation-delay:-.1s
}

@keyframes sk-circleBounceDelay{
  0%,80%,to{
      transform:scale(0)
  }
  40%{
      transform:scale(1)
  }
}
